<script setup lang="ts">
import type { NuxtError } from "#app";
import type { FetchError } from "ofetch";
type ErrorMessageProps = {
  error: NuxtError | FetchError;
};
const props = defineProps<ErrorMessageProps>();
</script>

<template>
  <div class="error-message">
    <h2>Error {{ props.error.statusCode }}</h2>
    <p>Oops... there was a error loading this page</p>
    <NuxtLink to="/">Return home</NuxtLink>
  </div>
</template>

<style lang="scss">
.error-message {
  position: absolute;
  top: 10rem;
  left: 50%;
  width: 100%;
  max-width: 400px;

  a {
    color: black;
  }
}
</style>
